import Axios from "axios";
import { predefinedToast } from "../../utils/toast";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { saveAs } from "file-saver";

export function parseBoolean(boolString) {
  return boolString.toLowerCase() === "true";
}

export function calculateAge(dateString) {
  if (dateString) {
    // Parse the input date string in mm/dd/yyyy format
    const parts = dateString?.split("/");
    const birthDate = new Date(parts[2], parts[0] - 1, parts[1]);

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in years
    const age = currentDate.getFullYear() - birthDate.getFullYear();

    // Adjust the age if the birthday hasn't occurred yet this year
    // if (
    //   currentDate.getMonth() < birthDate.getMonth() ||
    //   (currentDate.getMonth() === birthDate.getMonth() &&
    //     currentDate.getDate() < birthDate.getDate())
    // ) {
    //   age--;
    // }

    return age;
  }
}

export function calculateAgeDash(dateString) {
  if (dateString) {
    // Parse the input date string in mm/dd/yyyy format
    const parts = dateString?.split("-");
    const birthDate = new Date(parts[2], parts[0] - 1, parts[1]);

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in years
    const age = currentDate.getFullYear() - birthDate.getFullYear();

    // Adjust the age if the birthday hasn't occurred yet this year
    // if (
    //   currentDate.getMonth() < birthDate.getMonth() ||
    //   (currentDate.getMonth() === birthDate.getMonth() &&
    //     currentDate.getDate() < birthDate.getDate())
    // ) {
    //   age--;
    // }

    return age;
  }
}

export async function addRemoveItemCart(
  cartID,
  wellnessServiceID,
  operation,
  refreshState,
  quantity
) {
  // operation types "Add"/”Remove”
  try {
    let response = await Axios.post(
      `https://coverfi.apimachine.com/api/v1/client-user/cart/update/quantity/${cartID}`,
      {
        wellness_service_id: wellnessServiceID,
        quantity: quantity ? quantity : 1,
        operation: operation,
      }
    );
    console.log(response, "Add/remove Item Cart response");
    predefinedToast(
      `${operation == "Add" ? "Added item to cart" : "Removed item from cart"}`
    );
    refreshState((prev) => !prev);
  } catch (error) {
    console.log(error?.message, "Add & remove item cart error");
    refreshState((prev) => !prev);
  }
}

export function convertDateFormatToUnderscore(inputDate) {
  try {
    // Parse the input date
    const inputDateObj = new Date(inputDate);

    // Check if the input date is valid
    if (isNaN(inputDateObj.getTime())) {
      throw new Error("Invalid date format");
    }

    // Convert the date to the desired format
    const outputDate = `${(inputDateObj.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${inputDateObj
      .getDate()
      .toString()
      .padStart(2, "0")}-${inputDateObj.getFullYear()}`;

    return outputDate;
  } catch (error) {
    return null;
  }
}

export function objectValidCheck(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
        return false; // Return false if any field is empty or undefined
      }
    }
  }
  return true; // All fields have values
}

export function convertFamilyDefinitionValue(famDef) {
  switch (famDef) {
    case "Employee only":
      return "Only The Employee";

    case "Employee plus spouse":
      return "Employee + Spouse";

    case "Employee plus spouse, 2 children up to 25 years":
      return "Employee + Spouse + 2 Children";

    case "Employee plus spouse, 2 Children up to 25 years, Parents OR Parent-in-Law to 80 years":
      return "Employee + Spouse + 2 Children + 2 Parents";

    case "Employee plus spouse, 2 Children up to 25 years, Parents & Parent-in-Law to 80 years":
      return "Employee + Spouse + 2 Children + 2 Parents + 2 In-Laws";

    default:
      break;
  }
}

export function removeEmptyFields(myObject) {
  // Create a new object with non-empty fields using Object.entries and filter
  const filteredObject = Object.fromEntries(
    Object.entries(myObject).filter(
      ([key, value]) => value !== "" && value !== null
    )
  );

  return filteredObject;
}

export async function getCompanyIDGlobal(email) {
  try {
    let response = await Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/user/getuserallprofile?user_email=${email}`
    );

    return response?.data?.data?.hrDetails?.hrCompanyDetails?._id;
  } catch (error) {
    console.log(error?.message, "getCompanyID error");
  }
}

export function filterObjectsWithAllTruthy(arr) {
  return arr.filter((obj) => {
    // Convert the object to an array of its values
    const values = Object.values(obj);

    // Check if all values are truthy
    return values.every((value) => value);
  });
}

export function allValuesTruthyObject(obj) {
  return Object.values(obj).every((value) => value === true);
}

export function anyValueTruthyObject(obj) {
  return Object.values(obj).some((value) => value === true);
}

export function getCurrentDate() {
  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, "0");
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // January is 0, so we add 1
  const year = currentDate.getFullYear();

  const formattedDate = `${day}-${month}-${year}`;
  return formattedDate;
}

export function checkPolicyNumberExist(policyNumber, setState) {
  Axios.get(
    `https://coverfi.apimachine.com/api/v1/policy?policy_number=${policyNumber}`
  )
    .then((response) => {
      setState(response?.data?.status);
    })
    .catch((error) => {
      console.log("Policy nuumber exist check API error");
    });
}

export function logoutToSameLogin(history, setDashboardHam, userLoginHandler) {
  if (setDashboardHam) {
    setDashboardHam(false);
  }
  if (userLoginHandler) {
    userLoginHandler();
  }
  localStorage.clear();
  if (window?.location?.pathname?.includes("coverfi")) {
    history.push(`/employer/login`);
  } else if (window?.location?.pathname?.includes("rahax")) {
    history.push(`/employee/login`);
  } else if (window?.location?.pathname?.includes("agency")) {
    history.push(`/associates/login`);
  } else if (window?.location?.pathname?.includes("teamlead")) {
    history.push(`/teamlead/login`);
  } else if (window?.location?.pathname?.includes("verticalhead")) {
    history.push(`/verticalhead/login`);
  } else if (window?.location?.pathname?.includes("businesshead")) {
    history.push(`/businesshead/login`);
  } else if (window?.location?.pathname?.includes("brokerapp")) {
    history.push(`/admin/login`);
  } else if (window?.location?.pathname?.includes("affiliates")) {
    history.push(`/affiliates/login`);
  }
}

export function base64ToBlob(base64String) {
  const byteCharacters = atob(base64String);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: "application/pdf" });
  return blob;
}

export function downloadFile(blob) {
  saveAs(blob, "healthcard.pdf");
}

export function checkAllFieldsHaveValuesArrayOfObjects(arr) {
  return arr.every((obj) => {
    const values = Object.values(obj);
    return values.every(
      (value) => value !== undefined && value !== null && value !== ""
    );
  });
}

export function checkAllFieldsHaveValuesObjects(obj) {
  const values = Object.values(obj);
  return values.every(
    (value) => value !== undefined && value !== null && value !== ""
  );
}

export async function parseAddress(addressString) {
  const addressParts = addressString.split(", ");

  if (addressParts.length < 2) {
    // Handle invalid address format
    return null;
  }

  const city = addressParts[0];
  const statePostalCode = addressParts[1];

  const statePostalCodeParts = statePostalCode.split(" ");
  const state = statePostalCodeParts.slice(0, -1).join(" ");
  const postalCode = statePostalCodeParts.slice(-1)[0];

  const addressObject = {
    city: city,
    state: state,
    postalCode: postalCode,
  };

  return await addressObject;
}

export function generateArray(start, end, step) {
  const result = [];
  for (let i = start; i <= end; i += step) {
    result.push(`${i}`);
  }
  return result;
}
